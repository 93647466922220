import React from "react";
import sortBy from "lodash/sortBy";
import every from "lodash/every";
import {LockOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

import Styled from "./styled";
import MissionImg from "assets/img/MissionImg.jpg";
import HomeCourseCard from "./Home.CourseCard";
import assesstmentsIMG from "assets/img/assesstmentsIMG.jpg";
import expiredIMG from "assets/img/expired.png";
import SponsorImg from "assets/img/SponsorIMG.png";
import Logo from "assets/img/logo.png";
import {coursesSelector} from "services/courses/courses.selectors";
import Link from "components/Link/Link";
import {progressSelector} from "services/progress/progress.selector";
import {urlLocations} from "routes/urlLocations";
import {ReactComponent as ArrowIcon} from "assets/img/arrow.svg";
import {introVideoSelector} from "../../services/appInfo/appInfo.selector";
import {isReplica} from "../../constants";
import CountdownTimer from "./Timer/CountdownTimer";
import {profileSelector} from "../../services/profile/profile.selectors";
import {Alert} from "antd";

const EXPIRE_DAYS_IN_MS = 120 * 24 * 60 * 60 * 1000;

const Home = () => {
  const courses = useSelector(coursesSelector);
  const progress = useSelector(progressSelector);
  const introVideo = useSelector(introVideoSelector);
  const profile = useSelector(profileSelector);

  let coursesProgress;
  if (progress) {
    const {finalAssessment, ...res} = progress;
    coursesProgress = res;
  }

  const timeToExpire = new Date(profile?.created_at).getTime() + EXPIRE_DAYS_IN_MS;
  const dateToExpire = new Date(timeToExpire);
  const isExpired = isReplica ? timeToExpire - new Date().getTime() < 0 : false;

  return (
    <>
      <Styled.Intro>
        <Styled.IntroContainer>
          <Styled.IntoCapture>{isReplica ? "About the PHCP Diabetes Training Program" : "About the ADA Program"}</Styled.IntoCapture>
          <Styled.IntroTitle>
            The path to understanding diabetes starts here
          </Styled.IntroTitle>
        </Styled.IntroContainer>
      </Styled.Intro>
      <Styled.Mission id={"about"}>
        <Styled.MissionContainer>
          <Styled.MissionImg src={MissionImg}/>
          <Styled.MissionText>
            <Styled.MissionCapture>Course Description</Styled.MissionCapture>
            <Styled.MissionVideo>
              {introVideo?.media?.[0]?.url && (
                <video controls preload={"true"}>
                  <source
                    src={introVideo?.media?.[0]?.url}
                    type={introVideo?.media?.[0]?.mime}
                  />
                </video>
              )}
            </Styled.MissionVideo>
            {/*<Styled.MissionDesc>*/}
            {/*  <p>*/}
            {/*    <b>*/}
            {/*      Whether you’ve been newly diagnosed, have been fighting*/}
            {/*      against type 1 or type 2 diabetes for a while, or are helping*/}
            {/*      a loved one, you’ve come to the right place.*/}
            {/*    </b>*/}
            {/*    This is the start of gaining a deeper understanding of how you*/}
            {/*    can live a healthier life — with all the tools, health tips, and*/}
            {/*    food ideas you need.*/}
            {/*  </p>*/}
            {/*  <span>*/}
            {/*    Wherever you’re at with your diabetes, know that you have*/}
            {/*    options and that you don’t have to be held back. You can still*/}
            {/*    live your best life. All you have to do is take action and stick*/}
            {/*    with it.*/}
            {/*  </span>*/}
            {/*</Styled.MissionDesc>*/}
          </Styled.MissionText>
        </Styled.MissionContainer>
      </Styled.Mission>
      <Styled.Courses id={"courses"}>
        <Styled.CoursesContainer>
          <Styled.CoursesHeader>
            <Styled.CoursesTitle>Our Courses</Styled.CoursesTitle>
            {isReplica && <CountdownTimer targetDate={timeToExpire}/>}
          </Styled.CoursesHeader>
          {isReplica && !isExpired && <Alert style={{borderRadius: 10, marginBottom: 16}} type={'warning'}
                  message={`Programme completion deadline has been reached in order for you to obtain CME hours. You can continue accessing the content until ${dateToExpire.toLocaleDateString()} for your reference.`}
                  showIcon/>}
          {!isExpired && <Styled.CoursesList>
            {sortBy(courses, "rank")?.map((course, index) => (
              <Styled.CoursesItem key={course?.id}>
                <HomeCourseCard
                  index={index}
                  prevCourseId={sortBy(courses, "rank")?.[index - 1]?.id}
                  course={course}
                />
              </Styled.CoursesItem>
            ))}
          </Styled.CoursesList>}
        </Styled.CoursesContainer>
      </Styled.Courses>
      {!isExpired && <Styled.FinalAssessment>
        <Styled.FinalAssessmentContainer>
          <Styled.FinalAssessmentImg src={assesstmentsIMG}/>
          <Styled.FinalAssessmentInfo>
            <Styled.FinalAssessmentText>
              <Styled.FinalAssessmentCapture>
                Exam
              </Styled.FinalAssessmentCapture>
              <Styled.FinalAssessmentTitle>
                Final Assessment
              </Styled.FinalAssessmentTitle>
              <span>
                You’re almost there! Please take the following final assessment to gain your certificate.
              </span>
            </Styled.FinalAssessmentText>
            <>
              {coursesProgress &&
              !!every(Object.values(coursesProgress), {isCompleted: true}) ? (
                <Styled.ExploreMore>
                  <Link to={urlLocations.finalAssessment}>
                    Explore More <ArrowIcon/>
                  </Link>
                </Styled.ExploreMore>
              ) : (
                <Styled.FinalAssessmentTitleBlocked>
                  <Styled.FinalAssessmentTitleBlockedIcon>
                    <LockOutlined/>
                  </Styled.FinalAssessmentTitleBlockedIcon>
                  <div>
                    For opening this Final Assessment you should <br/>
                    pass all the previous courses
                  </div>
                </Styled.FinalAssessmentTitleBlocked>
              )}
            </>
          </Styled.FinalAssessmentInfo>
        </Styled.FinalAssessmentContainer>
      </Styled.FinalAssessment>}
      {isExpired && <Styled.FinalAssessment>
        <Styled.ExpiredContainer>
          <Styled.ExpiredImg src={expiredIMG}/>
          <Styled.FinalAssessmentInfo>
            <Styled.ExpiredText>
              <Styled.ExpiredCapture>
                Expired
              </Styled.ExpiredCapture>
              <Styled.FinalAssessmentTitle>
                You don't have access
              </Styled.FinalAssessmentTitle>
              <span>
                Your access to the courses has been expired, you were only allowed to access these courses for 30 days since you registered on the platform
              </span>
            </Styled.ExpiredText>
          </Styled.FinalAssessmentInfo>
        </Styled.ExpiredContainer>
      </Styled.FinalAssessment>
      }
      {!isReplica && <Styled.Sponsor>
        <Styled.SponsorContainer>
          <Styled.SponsorImg>
            <img src={SponsorImg} alt=""/>
          </Styled.SponsorImg>
          <Styled.SponsorContent>
            <Styled.SponsorContentWrapper>
              <Styled.SponsorLogo>
                <img src={Logo} alt=""/>
              </Styled.SponsorLogo>
              <Styled.SponsorCapture>program sponsor</Styled.SponsorCapture>
              <Styled.SponsorTitle>
                American Diabetes Academy
              </Styled.SponsorTitle>
              <Styled.SponsorDesc>
                <ul>
                  {isReplica &&
                    <li>SNDC is a leading national reference center for controlling and preventing diabetes and
                      Participating in raising the quality of healthy living and promoting public health in the kingdom
                      of Saudi Arabia.
                    </li>
                  }
                  <li>ADA Diabetes Academy is a one stop destination that provides comprehensive
                    information on diabetes management, in line with the latest standards of care.
                  </li>
                  <li>We are honored to invite you to the Diabetes Management Program.</li>
                  <li>The ADA diabetes academy is brought to you by Sanofi in line with its
                    commitment towards medical education to HCPS.
                  </li>
                  <li>The course content is provided by the American diabetes association.</li>
                  <li>We aim to improve prediabetes, diabetes and cardiometabolic care through
                    advanced education for our HCPs.
                  </li>
                </ul>
              </Styled.SponsorDesc>
              {/*<Styled.SponsorButton href={"#"}>*/}
              {/*  Learn More*/}
              {/*  <ArrowIcon />*/}
              {/*</Styled.SponsorButton>*/}
            </Styled.SponsorContentWrapper>
          </Styled.SponsorContent>
        </Styled.SponsorContainer>
      </Styled.Sponsor>}
    </>
  );
};

export default Home;
