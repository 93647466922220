import styled from "styled-components";
import Link from "components/Link/Link";
import Button from "components/Button/Button";

const Title = styled.div`
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 50px;
`;

const ForgotPasswordLinkWrapper = styled.div`
  text-align: center;
`;

const ForgotPasswordLink = styled(Link)`
  display: inline-block;
  margin: 32px auto;
  text-decoration: none;
`;

const Desc = styled.div`
  color: #8e8e8e;
  font-size: 12px;
  margin-top: 32px;
`;

const SubTitle = styled.div`
  font-size: 18px;
  text-align: center;
`;

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const LoginButton = styled(Button)`
  width: 100%;
  svg {
    display: inline-block;
    vertical-align: middle;
    transform: rotate(180deg);
    margin-left: 8px;
    width: 14px;
    height: 7px;
    path {
      stroke: #ffffff;
      transition: stroke 0.3s;
    }
  }
  &:hover {
    svg {
      path {
        stroke: #7a00e6;
      }
    }
  }
`;

const RememberMeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-bottom: 32px;
`;

export default {
  Title,
  SubTitle,
  ForgotPasswordLink,
  Desc,
  Container,
  ForgotPasswordLinkWrapper,
  InputWrap,
  LoginButton,
  RememberMeWrapper,
};
